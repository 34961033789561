.modal-adblock-detector {
  & > div {
    background-color: var(--color-danger-lighter);
    border: 3px solid var(--color-danger-light);
  }

  &__icon {
    font-size: 2.5rem;
    color: var(--color-danger-main);
    background-color: var(--color-danger-light);
    padding: var(--spacing-xs);
    border-radius: 50%;
    margin-bottom: var(--spacing-xs);
  }
}
